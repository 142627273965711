import { useQuery } from 'react-query';

import Api from '../api';

const QueryKey = {
  ImpersonationUsers: ['impersonation', 'users'],
};

export function useGetImpersonationUsers() {
  return useQuery(QueryKey.ImpersonationUsers, Api.getImpersonationUsers);
}
