import { noop } from 'lodash';
import React from 'react';

import { User } from '../model/user';

export interface ImpersonationState {
  loggedInUser?: User;
  users: User[];
  impersonatedUser?: User;
  setImpersonatedUser: (user: User | undefined) => void;
  currentUser?: User;
}

export const ImpersonationContext = React.createContext<ImpersonationState>({
  users: [],
  setImpersonatedUser: noop,
});

export const useImpersonation = (): ImpersonationState => {
  return React.useContext(ImpersonationContext);
};
