import _ from 'lodash';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import { useAuth } from '../context/auth';
import { ImpersonationContext, ImpersonationState } from '../context/impersonation';
import { User } from '../model/user';
import { useGetImpersonationUsers } from '../queries/impersonation';

let impersonatedUserId: string | undefined = undefined;

export const getImpersonatedUserId = () => {
  return impersonatedUserId;
};

export const ImpersonationContainer = ({ children }: { children: ReactNode }) => {
  const { userAttributes } = useAuth();
  const { data: users } = useGetImpersonationUsers();
  const sortedUsers = useMemo(() => _.sortBy(users, ['firstName', 'lastName']), [users]);
  const loggedInUser = useMemo(
    () => _.find(users, (user) => user.email === userAttributes?.email),
    [users, userAttributes],
  );
  const queryClient = useQueryClient();

  const [impersonatedUser, setImpersonatedUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    // Store the current user ID outside of the React state
    impersonatedUserId = impersonatedUser?.id;
    // Reset all non-impersonation queries
    void queryClient.resetQueries({ predicate: (query) => query.queryKey[0] !== 'impersonation' });
  }, [impersonatedUser, queryClient]);

  const currentUser = impersonatedUser || loggedInUser;

  const impersonationState: ImpersonationState = {
    loggedInUser,
    users: sortedUsers,
    impersonatedUser,
    setImpersonatedUser,
    currentUser,
  };

  return <ImpersonationContext.Provider value={impersonationState}>{children}</ImpersonationContext.Provider>;
};
