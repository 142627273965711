import { CognitoUser } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';
import React, { useContext } from 'react';

import Api from '../api';
import { LegalEventType } from '../api/legal-event';

export enum AuthStatus {
  INITIAL,
  AUTHENTICATED,
  UNAUTHENTICATED,
}

// Buyside Terms of Service accepted or not
export enum BuysideTosStatus {
  INITIAL,
  TOS_NOT_ACCEPTED,
  TOS_ACCEPTED,
}

export interface UserAttributes {
  firstName: string;
  lastName: string;
  email: string;
}

export interface AuthState {
  status: AuthStatus;
  tosStatus?: BuysideTosStatus;
  user?: CognitoUser;
  userAttributes?: UserAttributes;
}

export const AuthContext = React.createContext<AuthState>({ status: AuthStatus.INITIAL });

export function useAuth() {
  return useContext(AuthContext);
}

export async function signOut(logSignOut = true) {
  if (logSignOut) {
    try {
      await Api.logLegalEvent(LegalEventType.SIGN_OUT);
    } finally {
      await Auth.signOut();
    }
  } else {
    await Auth.signOut();
  }
}
