export enum AppConfigType {
  SSER,
  SAHARA,
}

export interface LabelTexts {
  Portfolio: string;
  BroadShortSingular: string;
  BroadLongSingular: string;
  BroadNeutral: string;
  FocusUnassigned: string;
  Unassigned: string;
  RankedLongPlural: string;
  RankedLongSingular: string;
  RankedShortPlural: string;
  RankedShortSingular: string;
  Focus: string;
  BenchLongPlural: string;
  BenchLongSingular: string;
  BenchLongAsMoveDestination: string;
  BenchShortPlural: string;
  BenchShortSingular: string;
  BenchShortAsMoveDestination: string;
  Broad: string;
  BroadNeutralAsMoveDestination: string;
  RankedNeutral: string;
  NoOpinion: string;
  NoCoverage: string;
  RemoveCompany: string;
}

const sserLabelTexts = {
  Portfolio: 'Portfolio',
  BroadShortSingular: 'Broad Long',
  BroadLongSingular: 'Broad Short',
  RankedLongPlural: 'Focus Longs',
  RankedLongSingular: 'Focus Long',
  RankedShortPlural: 'Focus Shorts',
  RankedShortSingular: 'Focus Short',
  Focus: 'Focus',
  BenchLongPlural: 'Broad Longs',
  BenchLongSingular: 'Broad Long',
  BenchLongAsMoveDestination: ' Broad Long',
  BenchShortPlural: 'Broad Shorts',
  BenchShortSingular: 'Broad Short',
  BenchShortAsMoveDestination: ' Broad Short',
  Broad: 'Broad',
  BroadNeutral: 'Broad Neutral',
  BroadNeutralAsMoveDestination: ' Broad Neutral',
  FocusUnassigned: 'Focus Unassigned',
  Unassigned: 'Unassigned',
  RankedNeutral: 'Focus Neutral',
  NoOpinion: 'No Opinion',
  NoCoverage: 'No Coverage',
};

const buysideLabelTexts = {
  RankedLongPlural: 'Ranked Longs',
  RankedLongSingular: 'Ranked Long',
  RankedShortPlural: 'Ranked Shorts',
  RankedShortSingular: 'Ranked Short',
  BenchLongPlural: 'Long Watchlist',
  BenchLongSingular: 'Long Watchlist',
  BenchLongAsMoveDestination: 'Long Watchlist',
  BenchShortPlural: 'Short Watchlist',
  BenchShortSingular: 'Short Watchlist',
  BenchShortAsMoveDestination: 'Short Watchlist',
  RankedNeutral: 'Neutral',
  NoOpinion: 'No Opinion',
  NoCoverage: 'No Coverage',
  RemoveCompany: 'Remove Company',
};

export interface AppConfig {
  appType: AppConfigType;
  appName: string;
  favicon: string;
  appleTouchIcon: string;
  manifest: string;
  ideaEntryEnabled: boolean;
  priceTargetEnabled: boolean;
  themesEnabled: boolean;
  labelTexts: LabelTexts;
  emailContact?: string;
  portfolioPerformancePageEnabled: boolean;

  impersonationEnabled: boolean;
}

const sserAppConfig = {
  appType: AppConfigType.SSER,
  appName: 'SSER',
  favicon: '/react-favicon.ico',
  appleTouchIcon: '/react-logo192.png',
  manifest: '/sser-manifest.json',
  ideaEntryEnabled: true,
  priceTargetEnabled: true,
  themesEnabled: true,
  labelTexts: sserLabelTexts,
  impersonationEnabled: true,
} as AppConfig;

const buysideAppConfig = {
  appType: AppConfigType.SAHARA,
  appName: 'Sahara by Two Sigma',
  favicon: '/ts-favicon.ico',
  appleTouchIcon: '/ts-apple-touch-icon-152x152.png',
  manifest: '/sahara-manifest.json',
  ideaEntryEnabled: false,
  priceTargetEnabled: false,
  themesEnabled: false,
  labelTexts: buysideLabelTexts,
  emailContact: 'sahara-support@twosigma.com',
  impersonationEnabled: false,
} as AppConfig;

export function getAppConfig(appType: AppConfigType): AppConfig {
  switch (appType) {
    case AppConfigType.SSER:
      return sserAppConfig;
    case AppConfigType.SAHARA:
      return buysideAppConfig;
  }
}
